@import "../vendor/bootstrap/less/bootstrap";
@import "../vendor/font-awesome/less/font-awesome";
@import "fonts";
@import "variables";
/*
* Vendor specific
*/
@import "vendors/parsley";
@import "vendors/slick";

html, body {
  height: 100%;
}

#container {
  min-height: 100%;
  margin-bottom: -44px;
  padding-bottom: 44px;
}

#notifications-wrapper{
  padding: 10px 0 10px 0;
}

#sidebar {
  padding-top: 1 * @font-size-h1;
}

.mt-1 {
  margin-top: @line-height-computed * 1px;
}

.mt-2 {
  margin-top: @line-height-computed * 2px;
}

.mt-3 {
  margin-top: @line-height-computed * 3px;
}

.mb-1 {
  margin-bottom: @line-height-computed * 1;
}

.mb-2 {
  margin-bottom: @line-height-computed * 2;
}

.mb-3 {
  margin-bottom: @line-height-computed * 3;
}

.align-right {
  float: right;
  &.matrix-image {
    padding:20px 0px 20px 20px;
  }
}

.align-left {
  float: left;
  &.matrix-image {
    padding:20px 20px 20px 0px;
  }
}

.align-right, .align-left {
  @media (max-width: @screen-xs) {
    padding-left: 0;
    padding-right: 0;
    float: none;
  }
}

.clear-both {
  clear: both;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.text-align-center {
  text-align: center;
}

.vertical-align-middle {
  position: relative;
  top: 50%;
  .translate(0%, -50%);
}

.horizontal-align-center {
  .translate(-50%, -50%);
  left: 50%;
  position: absolute;
  z-index: 99;
}

/*
    Theme Name: Baker
    Theme URL: https://bootstrapmade.com/baker-free-onepage-bootstrap-theme/
    Author: BootstrapMade.com
    Author URL: https://bootstrapmade.com
*/
@-webkit-keyframes "spin" {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes "spin" {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Add animation to "page content" */
@-webkit-keyframes "animatebottom" {
  from {
    bottom: -100px;
    opacity: 0;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}
@keyframes "animatebottom" {
  from {
    bottom: -100px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

body {
  line-height: 1.4;
  font-family: @font-family-sans-serif;
  color: #67696c;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.5px;
  overflow-x: hidden;
}
ol {
  list-style: upper-roman;
}
ul {
  list-style: circle;
}
blockquote {
  quotes: none;
  &:before {
    content: '';
    content: none;
  }
  &:after {
    content: '';
    content: none;
  }
}
q {
  quotes: none;
  &:before {
    content: '';
    content: none;
  }
  &:after {
    content: '';
    content: none;
  }
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
h1, h2, h3, h4, h5, h6 {
  font-family: @heading-font;
  font-weight: 500;
}
.section-padding {
  padding: 60px 0px;
  &img {
    padding:20px;
  }
}
#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s;
}
.header {
  min-height: 120px;
  position: relative;
}
.navbar-default {
  background-color: transparent;
  border: 0px;
  padding: 15px 0;
  transition: all 0.1s;
  .navbar-nav {
    >li {
      >a {
        padding: 5px 10px;
        font-size: 14px;
        line-height: 54px;
        font-weight: 400;
        text-transform: uppercase;
        background: transparent;
        color: @text-color;
        -webkit-transition: color 0.2s ease-out;
        transition: color 0.2s ease-out;
        &:focus {
          background-color: transparent;
          color: @brand-primary;
        }
        &:hover {
          background-color: transparent;
          color: @brand-primary;
        }
      }
    }
    >.active {
      >a {
        color: @brand-primary;
        background-color: transparent;
        &:focus {
          color: @brand-primary;
          background-color: transparent;
        }
        &:hover {
          color: @brand-primary;
          background-color: transparent;
        }
      }
    }
  }
}
.top-nav-collapse, .header {
  padding: 10px 0px;
  background: @white;
  box-shadow: 0px 0px 15px 0px #666;
}
.logo-dec {
  color: @brand-primary;
  // width: 100%;
}
.navbar-brand {
  padding: 0px;
  padding: 0px;
  margin-top: 0px;
  img {
    width:200px;
  }
}
.banner-info {
  padding: 30px;
  color: @white;
}
.bnr-title {
  font-family: @banner-font;
}
.bnr-sub-title {
  font-family: @banner-font;
}
h1.bnr-title {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
h2.bnr-sub-title {
  font-size: 45px;
  text-transform: uppercase;
  padding: 20px 0px;
  font-weight: 700;
}
.bnr-para {
  font-weight: 300;
}
.btn-download, .btn-primary {
  text-transform: uppercase;
  margin-top: 50px;
  color: @white;
  border: 0;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  padding: 10px 30px;
  border-radius: @border-radius;
  margin: 50px 10px 0 0;
  outline: none !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.3s;
  background-color: @brand-primary;
  &:hover, &:focus {
    text-transform: uppercase;
    margin-top: 50px;
    color: @white;
    border: 0;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    padding: 10px 30px;
    border-radius: @border-radius;
    margin: 50px 10px 0 0;
    outline: none !important;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: all 0.3s;
  }
}
.btn-more, .btn-more:focus, .btn-secondary, .btn-secondary:focus {
  text-transform: uppercase;
  margin-top: 50px;
  color: @white;
  border: 0;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  padding: 10px 30px;
  border-radius: @border-radius;
  margin: 50px 10px 0 0;
  outline: none !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.3s;
  margin-left: 15px;
  border: 1px solid @brand-primary;
  color: @brand-primary;
  &:hover {
    background: @brand-primary;
  }
}
.overlay-detail {
  position: relative;
  width: 100%;
  margin-top: 25px;
  text-align: center;
  a {
    i {
      font-size: 38px;
      color: @white;
    }
  }
}
.title, .service-title {
  font-family: @banner-font;
  font-size: 28px;
  text-transform: uppercase;
}
.item-img {
  margin-bottom: 15px;
  font-size:70px;
  .img-responsive {
    width: 50px;
    margin: 0px auto;
  }
}
.sub-title {
  font-size: 20px;
}
.bottom-line {
  margin-top: 20px;
  margin-bottom: 40px;
  border: 0;
  border-top: 0px;
  height: 3px;
  width: 40px;
  background-color: @brand-primary;
}
.wrap-item {
  position:relative;
  padding-bottom: 40px;
  height:100%;
  h3 {
    font-size: 24px;
    line-height: 25px;
  }
  a {
    position:absolute;
    bottom:0px;
    display:block;
    text-align: center;
    width:100%;
  }
}
.pad-bt15 {
  padding-bottom: 15px;
}
.sec-sub-title {
  padding-top: 30px;
  font-size: 16px;
  font-weight: bold;
  color: #002F2F;
  text-transform: uppercase;
  margin: 25px 0px;
  line-height: 1.7;
}
.light {
  background-color: @light;
}
#footer {
  background-color:#414042;
  color:#ffffff;
  text-align: center;
}
.red {
  background-color: @brand-primary;
  h1, h2, h3, h4, h5, h6, p {
    color:@white;
  }
}
ul.service-list {
  li {
    i {
      color: #002F2F;
      padding-right: 10px;
    }
  }
}
.learn-more, .learn-more:hover, .learn-more:focus {
  height: 50px;
  width: 175px;
  background: #046380;
  display: inline-block;
  text-align: center;
  color: @white;
  line-height: 50px;
  text-transform: uppercase;
  font-size: 16px;
  text-decoration: none;
  margin-right: 10px;
  margin-top: 45px;
  text-decoration: none;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.visit-portfolio, .visit-portfolio:hover, .visit-portfolio:focus {
  height: 50px;
  width: 175px;
  display: inline-block;
  text-align: center;
  color: #495376;
  line-height: 50px;
  text-transform: uppercase;
  font-size: 16px;
  text-decoration: none;
  border: 1px solid #838aa1;
  margin-top: 45px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.bx-wrapper {
  img {
    width: 70px;
    display: inline-block;
    border-radius: 50%;
    border: 5px solid @white;
    margin-bottom: 15px;
  }
  margin: 0 auto 35px;
}
.service-item {
  h3 {
    span {
      opacity: 0.7;
      font-size: 75px;
      line-height: 1;
      text-transform: uppercase;
      letter-spacing: -5px;
      font-weight: 900;
      padding-right: 6px;
    }
    font-weight: 700;
  }
  p {
    opacity: 0.6;
    color: inherit;
    padding: 10px 0px;
  }
  a {
    color: @brand-primary;
  }
}
.mr-btn-15 {
  margin-bottom: 15px;
}
.padding-right-zero {
  padding-right: 0px !important;
}
.portfolio-item {
  position: relative;
  overflow: hidden;
  figure {
    margin: 0;
    padding: 0;
    position: relative;
    cursor: pointer;
    img {
      display: block;
      position: relative;
      z-index: 10;
      margin: -15px 0;
      backface-visibility: hidden;
      -webkit-transition: all .5s;
      -moz-transition: all .5s;
      transition: all .5s;
    }
    figcaption {
      display: block;
      position: absolute;
      z-index: 5;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 59px 30px;
      background-color: @brand-primary;
      backface-visibility: hidden;
      -webkit-transform: rotateY(-180deg);
      -moz-transform: rotateY(-180deg);
      transform: rotateY(-180deg);
      -webkit-transition: all .5s;
      -moz-transition: all .5s;
      transition: all .5s;
      text-align: center;
    }
    h2 {
      font-family: @banner-font;
      color: @white;
      font-size: 20px;
      text-align: left;
      padding-bottom: 15px;
    }
    p {
      display: block;
      line-height: 18px;
      margin: 0;
      color: @white;
      text-align: left;
    }
    &:hover {
      img {
        -webkit-transform: rotateY(180deg);
        -moz-transform: rotateY(180deg);
        transform: rotateY(180deg);
      }
      figcaption {
        -webkit-transform: rotateY(0);
        -moz-transform: rotateY(0);
        transform: rotateY(0);
      }
    }
  }
}
figure.hover {
  img {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
  figcaption {
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    transform: rotateY(0);
  }
}
.testimonials {
  min-height: 390px;
}

.testimonial-item {
      blockquote {
        font-size: 18px;
        line-height: 32px;
        color: @white;
        font-weight: 300;
        text-shadow: 1px 1px 2px #333;
        text-align: center;
        display: inline-block;
        width: 100%;
        margin: 20px 0;
        padding: 0 80px;
        position: relative;
        border: 0;
        &::before {
          content: '“';
          left: 30px;
          position: absolute;
          font-weight: 300;
          font-size: 5em;
          font-family: Georgia, serif;
          color: @white;
          top: 20px;
        }
        &::after {
          position: absolute;
          font-weight: 300;
          font-size: 5em;
          font-family: Georgia, serif;
          color: @white;
          top: 20px;
          content: '”';
          right: 30px;
        }
      }
      small {
        font-size: 16px;
        color: @white;
        font-weight: 400;
        text-shadow: 1px 1px 2px #333;
        text-align: center;
        display: inline-block;
        width: 100%;
      }
}
.bx-viewport {
  height: 235px !important;
  background-color: transparent !important;
  border: 0px none !important;
  box-shadow: none !important;
}
.blog-info {
  h2 {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 1px;
    padding-top: 5px;
  }
  p {
    color: #949494;
  }
}
.blog-comment {
  margin: 12px 0px;
  min-height: 30px;
  p {
    color: #949494;
    font-size:12px;
    margin:0px;
    i {
      color: #949494;
    }
  }
}
.read-more {
  color: #949494;
  margin-top: 15px;
  display: inline-block;
}
.blog-img {
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
  img {
    &:hover {
      opacity: 1;
      -webkit-transform: scale(1.05);
      -moz-transform: scale(1.05);
      -ms-transform: scale(1.05);
      -o-transform: scale(1.05);
      transform: scale(1.05);
    }
    width: 100%;
    opacity: 0.8;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
  }
}

.btn-submit {
  background-color: transparent;
  border-radius: @border-radius;
  color: @brand-primary;
  font-weight: 400;
  border: 1px solid @brand-primary;
  font-size: 14px;
  padding: 6px 20px;
  &:hover {
    background-color: transparent;
    border-radius: @border-radius;
    color: @brand-primary;
    font-weight: 400;
    border: 1px solid @brand-primary;
    font-size: 14px;
    padding: 6px 20px;
  }
  &:focus {
    background-color: transparent;
    border-radius: @border-radius;
    color: @brand-primary;
    font-weight: 400;
    border: 1px solid @brand-primary;
    font-size: 14px;
    padding: 6px 20px;
  }
}
.form-control {
  background-color: transparent;
  border-radius: 0px;
  color: @brand-primary !important;
  border-color: @brand-primary;
  &:focus {
    border-color: @brand-primary;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
  }
}
.contact-form {
  input.form-control {
    color: @white;
  }
}
.validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 13px;
}
#sendmessage {
  color: green;
  border: 1px solid green;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 500;
  margin-bottom: 15px;
}
#errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 500;
  margin-bottom: 15px;
}
#sendmessage.show {
  display: block;
}
#errormessage.show {
  display: block;
}
.show {
  display: block;
}
.loction-info {
  p {
    margin-bottom: 25px;
    i {
      color: @brand-primary;
    }
  }
}
#footer {
  padding: 20px 0px;
  a {
    color: @brand-primary;
    font-weight: 400;
  }
  .site-footer {
    font-size:12px;
    i.fa {
        font-size: 25px;
        color: #fff;
        padding: 0px 10px;
        &:hover {
          color:@brand-primary;
        }
    }
  }
}
.scrollToTop {
  position: fixed;
  bottom: 12px;
  right: 12px;
  width: 42px;
  height: 42px;
  color: @white;
  background: @brand-primary;
  font-size: 14px;
  line-height: 42px;
  text-align: center;
  z-index: 100;
  cursor: pointer;
  display: none;
}
.white-bg {
  background-color: @white;
}
.cta {
  background-color: rgb(73, 84, 105);
  .cta-info {
    width: 100%;
    float: left;
    padding: 30px 25px 30px 15px;
    border-radius: 5px;
    .cta-txt {
      h3 {
        font-size: 32px;
        margin-bottom: 10px;
      }
    }
    .cta-btn {
      margin-top: 16px;
      background-color: rgb(190, 158, 33);
      padding: 20px 50px;
      color: @white;
      font-size: 22px;
      display: inline-block;
      transition: all 0.5s ease-in 0s;
    }
  }
}
@media (min-width: 768px) and (max-width: 980px) {
  .service-item {
    margin-bottom: 15px;
  }
  .blog-sec {
    margin-bottom: 15px;
  }
  .wrap-item {
    margin-bottom: 15px;
  }
  .loction-info {
    margin-bottom: 15px;
  }
  .portfolio-item {
    figure {
      figcaption {
        padding: 55px 30px;
      }
    }
  }
  .padding-right-zero {
    padding-right: 15px !important;
  }
}
@media (min-width: 451px) and (max-width: 767px) {
  .service-item {
    margin-bottom: 15px;
  }
  .blog-sec {
    margin-bottom: 15px;
  }
  .wrap-item {
    margin-bottom: 15px;
  }
  .loction-info {
    margin-bottom: 15px;
  }
}
@media (min-width: 20px) and (max-width: 450px) {
  .navbar-default {
    .navbar-brand {
      padding-left: 16px;
      margin-top: 0px;
      img {
        width:200px;
      }
    }
    .navbar-nav {
      >li {
        >a {
          line-height: 22px;
          text-align: center;
        }
      }
    }
  }
  h1.bnr-title {
    font-size: 16px;
  }
  h2.bnr-sub-title {
    font-size: 33px;
  }
  body {
    font-size: 14px;
  }
  .btn-download, .btn-primary {
    font-size: 12px;
    padding: 7px 18px;
    &:hover {
      font-size: 12px;
      padding: 7px 18px;
    }
    &:focus {
      font-size: 12px;
      padding: 7px 18px;
    }
  }
  .btn-more, .btn-secondary {
    font-size: 12px;
    padding: 7px 18px;
    &:hover {
      font-size: 12px;
      padding: 7px 18px;
    }
    &:focus {
      font-size: 12px;
      padding: 7px 18px;
    }
  }
  .wrap-item {
    h3 {
      font-size: 18px;
    }
    margin-bottom: 15px;
  }
  .title {
    font-size: 28px;
  }
  .service-title {
    font-size: 28px;
  }
  .sub-title {
    font-size: 15px;
  }
  .service-item {
    margin-bottom: 15px;
  }
  .blog-sec {
    margin-bottom: 15px;
  }
  .loction-info {
    margin-bottom: 15px;
    padding: 0px 15px;
  }
  .portfolio-item {
    figure {
      figcaption {
        padding: 30px 30px;
      }
      p {
        font-size: 14px;
      }
      h2 {
        font-size: 18px;
      }
    }
  }
  .padding-right-zero {
    padding-right: 15px !important;
  }
  .testimonial-item {
    ul {
      li {
        blockquote {
          font-size: 14px;
          line-height: 22px;
          margin: 10px 0;
          padding: 0 40px;
        }
      }
    }
  }
}
[data-slick-carousel-default] {
    margin-bottom: 0px;
}
.partner-logo {
  height:70px;
  padding: 0px 20px;
  .img-responsive {
    width:auto;
    height:100%;
  }
}
.slide {
  background-size:cover;
  background-position: center;
}

.page-header {
  margin:0px;
  background-color:@brand-primary;
  color:@white;
  padding:0px;
  h1 {
    font-size: 28px;
  }
  .article-sharing {
    margin:15px 0px;
  }
  a {
    color:@white;
    &.share-btn {
      margin-right:20px;
    }
  }
}
.gallery-image {
    padding: 8px;
    width: 33%;
    float: left;
    position: relative;
}

.iconlist {
  margin: 20px 0px;
  display:table;
  .icon {
    display: table-cell;
    float: left;
    padding-right: 15px;
    width:40px;
    font-size:25px;
    color:@brand-primary;
  }
  .content {
    display:table-cell;
    vertical-align: middle;
    width:100%;
  }
}

.contact {
  .iconlist {
    margin: 0px 0px 40px 0px;
    .icon {
      width:50px;
      font-size:15px;
    }
  }
}

.pagination {
  width:100%;
  .older {
    float:right;
  }
  .newer {
    float:left;
  }
}

form {
  .heading {
    display:none;
  }
}

.wrapper {
  background-color:rgba(0,0,0,0.3);
  .container{
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.navbar {
  min-height:100px;
}

div#myNavbar {
    position: relative;
    top: 50%;
    transform: translateY(20%);
}

.panel-default {
  border-color: @brand-primary;
  a {
    .panel-heading {
    color:@text-color;
    background-color: @white;
    border-color: @brand-primary;
    }
    &:hover, &:focus {
      text-decoration: none;
      .panel-heading {
        background-color:@brand-primary;
        h4 {
          color:@white;
        }
      }
    }
  }
  .panel-body {
    border-top:1px solid @brand-primary;
  }
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
  border-color: @brand-primary;
}
#four-col {
  top: 0px;
  &:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: white;
  }
  a{
    padding-bottom: 15px !important;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: self-end;
    margin-right: 0;
    margin-left: 0;
    justify-content: center;
  }
}
#news{
  top: 0px;
  &:hover {
    transition: all 0.2s ease-out;
    box-shadow: 4px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: white;
  }
}
#fields-fromPostalAddres{
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
  opacity: 0;
}